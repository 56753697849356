<template>
  <div class="cleaner-inspection-report mt-6">
    <div :class="containerClass" >
      <div class="pt-4 px-2">
        <div class="row mt-5 mx-2">
          <div class="col-md-12">
            <h4 class="text-left">Checklist:</h4>
          </div>
        </div>
        <spinner :show="loading" :use-modal="true" message="Please wait"></spinner>
        <div v-if="!loading" class="row">
          <div class="container position-relative mt-0 pb-5">
            <div @click="goPrev" class="position-absolute prev-button cursor-pointer">
              <i class="fas fa-chevron-left"></i>
            </div>

            <div @click="goNext" class="position-absolute next-button cursor-pointer">
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>

          <div class="container" v-if="selectedPlan">
            <swiper
              ref="extrasSwiper"
              :observer="true"
              :observeParents="true"
              :allowTouchMove="false"
              :slides-per-view="'auto'"
              :space-between="30"
              :loop="true"
              :pagination="true"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <swiper-slide v-for="(service, idx) in selectedPlan.services" :key="'service_'+idx" class="px-3">
                <service-check-list
                    :readonly="readonly"
                    :service-idx="idx"
                    :title="service.text"
                    :services="service.servicesList"
                    @update="onItemUpdate"
                />
              </swiper-slide>
            </swiper>
          </div>
        </div>

        <div class="row mt-5 mx-4">
          <div v-for="(title, area_key) in imageAreas" :id="area_key" class="col-md-6 my-2 px-2">
            <div class="file-card px-4 py-3 bordered">
              <h5>{{ title }}</h5>
              <image-uploader v-if="!readonly"
                :attr="attrForField"
                :url="url"
                input-css="primary-text primary-border"
                :process-result="processUpload"
                :form-data="imageFormData(area_key)"
                >
              </image-uploader>
              <div class="area-photos-container">
                <div class="scroll-x mt-3">
                  <div v-for="(image, idx) in images[area_key]" :key="idx" class="img-item text-center">
                    <img :src="image.thumb.url"><br/>
                    <a class="cursor-pointer" rel="nofollow" @click="removeImage(image.id)"><i class="fal fa-trash-alt" v-b-tooltip.hover title="Delete"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5 mx-4">
          <div class="col-md-12">
            <h4 class="text-left mb-3">Notes:</h4>
          </div>
          <div class="col-12 notes-container">
            <text-area-input v-model="note" name="notes" :readonly="formIsReadonly"></text-area-input>
            <template v-for="note in notes">
                <p>{{ note.note }}</p>
                <div class="user-information">
                  <img
                    class="avatar"
                    :src="note.avatar_thumb_url"
                    alt="User avatar">
                  <span class="username">{{ note.actor }}</span>
                  <span class="date">{{ note.time }}</span>
                </div>
            </template>
          </div>
        </div>

        <div class="row mt-5 mx-4">
          <div class="col-md-12">
            <h4 class="text-left mb-3">How do you rate your client?</h4>
          </div>
          <div class="col-auto">
            <star-rating v-model="rating" :show-rating="false" :star-size="30" inactive-color="#CCCCCC" active-color="#44A0ED" :glow="1" :rounded-corners="true" :padding="20" :read-only="readonly || hasRating" :increment="0.5"></star-rating>
          </div>
        </div>

        <div class="row mt-5 mx-4">
          <div class="col-md-12">
            <h4 class="text-left mb-3">Your feedback about the client:</h4>
          </div>
          <div class="col-12">
            <text-area-input v-model="feedback" name="feedback" :readonly="formIsReadonly"></text-area-input>
          </div>
        </div>

        <div class="row mt-5" v-if="!readonly">
          <div class="col-12 d-flex justify-content-center">
            <div class="mr-5">
              <button class="btn btn-secondary" @click="submit">SEND REPORT</button>
            </div>
            <div>
              <button class="btn btn-primary" @click="save">SAVE DRAFT</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import cleanerApi from '@/cleaners/api'
// import ExtrasSlider from '@/shared/extras_slider.vue'
import ImageUploader from '@/shared/uploaders/image.vue'
import TextAreaInput from '@/shared/inputs/text_area.vue'
import StarRating from 'vue-star-rating'
import ServiceCheckList from './service_check_list.vue'
import Spinner from '@/shared/spinner.vue'

import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

// Import Swiper styles
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination])

export default {
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        reportId: {
            type: Number,
            required: true
        },
        containerClass: {
          type: String,
          //default: "container my-4"
          default: "bg-white rounded-lg"
        }
    },
    components: {
        ImageUploader,
        TextAreaInput,
        StarRating,
        Spinner,
        Swiper,
        SwiperSlide,
        ServiceCheckList
    },
  data() {
    return {
        loading: false,
        allServices: [],
        images: {},
        imageAreas: [],
        notes: [],
        note: null,
        ourFeedback: null,
        planIdx: 0,
        rawFeedback: null,
        draft: null,
        rawRating: null,
        ourRating: null,
    }
  },
  computed: {
    selectedPlan() {
      return this.allServices.find(plan => plan.selected)
    },
    attrForField() {
      return {
        name: 'report_image[image]',
        key: 'id'
      }
    },
    hasRating() {
        return !!this.rawRating
    },
    feedback: {
        get() {
            if (this.rawFeedback) {
                return this.rawFeedback
            }
            return this.ourFeedback
        },
        set(v) {
            this.ourFeedback = v
        }
    },
    rating: {
        get() {
            if (this.rawRating) {
                return this.rawRating
            }
            return this.ourRating
        },
        set(v) {
            this.ourRating = v
        }
    },
    url() {
      return "/api/report_images"
    },
    formIsReadonly() {
      return this.readonly
    },
    params() {
        let completed = []
        Array.prototype.forEach.call(this.selectedPlan.services, (listOfServices, idx) => {
            completed[idx] = []
            Array.prototype.forEach.call(listOfServices.servicesList, ((service, serviceIdx) => {
                if (service.completed) {
                    completed[idx].push(serviceIdx)
                }
            }))
        })
        return { client_rating: this.rating, client_feedback: this.feedback, note: this.note, completed: completed }
    }
  },
  mounted() {
    this.getServicesForInspectionReport()
  },
  methods: {
    getReport() {
        this.loading = true
        cleanerApi.getReport(this.reportId).then(response => {
            this.images = response.data.images
            this.imageAreas = response.data.image_areas
            this.notes = response.data.notes
            this.draft = response.data.draft
            this.planIdx = response.data.plan_idx
            Vue.set(this.allServices[this.planIdx], 'selected', true)
            this.rawRating = parseFloat(response.data.rating)
            this.rawFeedback = response.data.feedback
            Array.prototype.forEach.call(response.data.completed, ((completed, serviceIdx) => {
                completed.forEach(cIdx => {
                  Vue.set(this.allServices[this.planIdx].services[serviceIdx].servicesList[cIdx], 'completed', true)
                })
            }))
            this.loading = false
        })
    },
    getServicesForInspectionReport() {
        this.loading = true
        cleanerApi.getServicesForInspectionReport().then(response => {
            this.allServices = response.data
            this.getReport()
        })
    },
    imageFormData(group) {
      return { 'report_image[inspection_report_id]': this.reportId, 'report_image[group]': group }
    },

    togglePlan(plan) {
      this.allServices.forEach(p => {
        p.selected = false 
      })

      plan.selected = true
    },
    onSwiper(swiper) {
    },
    onSlideChange() {
    },
    goNext() {
      this.$refs.extrasSwiper.$el.swiper.slideNext()
    },
    goPrev() {
      this.$refs.extrasSwiper.$el.swiper.slidePrev()
    },
    loadReportImages() {
      this.loading = true
      cleanerApi.getReportImages(this.reportId).then(response => {
        this.images = response.data.images
        this.imageAreas = response.data.image_areas
        this.loading = false
      }, error => {
        this.loading = false
        this.$flasher.error(error.response.data.errors)
      })
    },
    processUpload(result, key) {
      this.loadReportImages()
      return result
    },
    loadReportNotes() {
      this.loading = true
      cleanerApi.getReportNotes(this.reportId).then(response => {
        this.notes = response.data.notes
        this.loading = false
      }, error => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
      })
    },
    onItemUpdate({ serviceIdx, idx, completed }) {
        Vue.set(this.allServices[this.planIdx].services[serviceIdx].servicesList[idx], 'completed', completed)
    },
    removeImage(imageId) {
      this.loading = true
      cleanerApi.removeReportImage(imageId).then(response => {
        this.$flasher.success("Image deleted")
        this.loadReportImages()
      }, error => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
      })
    },
    save() {
      this.loading = true
      cleanerApi.saveReport(this.reportId, this.params).then(response => {
        this.note = null
        this.getReport()
      }, error => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
      })
    },
    submit() {
      this.loading = true
      cleanerApi.saveReport(this.reportId, this.params).then(response => {
          cleanerApi.submitReport(this.reportId).then(response => {
            window.location = "/"
          }, error => {
            this.loading = false
            this.$flasher.errors(error.response.data.errors)
          })
      }, error => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
      })
    }
  }
}
</script>
