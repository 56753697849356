<template>
    <div class="px-2 py-2">
        <b-card class="">
            <h4 class="card-title text-center">{{ title }}</h4>
            <b-list-group class="mx-5">
                <b-list-group-item v-for="(itemList, itemIdx) in services" :key="`key_${serviceIdx}_${itemIdx}`">
                    <b-form-checkbox :id="`item_${serviceIdx}_${itemIdx}`" :checked="itemList.completed" @change="onChange(itemIdx, $event)">{{ itemList.text }}</b-form-checkbox>
                </b-list-group-item>
                <div v-if="!readonly" class="text-left my-3">
                    <button class="btn btn-outline-secondary pill-btn mr-2" @click="selectAll">Select All</button>
                    <button class="btn btn-outline-secondary pill-btn" @click="deselectAll">Deselect All</button>
                </div>
            </b-list-group>
        </b-card>
    </div>
</template>
<script>
export default {
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        title: {
            required: true,
            type: String
        },
        services: {
            required: true,
            type: Array
        },
        serviceIdx: {
            required: true,
            type: Number
        }
    },
    methods: {
        selectAll() {
            this.services.forEach((service, idx) => {
                this.$emit('update', { serviceIdx: this.serviceIdx, idx: idx, completed: true })
            })
        },
        deselectAll() {
            this.services.forEach((service, idx) => {
                this.$emit('update', { serviceIdx: this.serviceIdx, idx: idx, completed: false })
            })
        },
        onChange(itemIdx, event) {
            this.$emit('update', { serviceIdx: this.serviceIdx, idx: itemIdx, completed: event })
        }
    }
}
</script>
